<template>
    <div>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" style="display:none" preserveAspectRatio="xMidYMid meet">
            <symbol id="icon-grua" fill="currentColor" viewBox="0 0 22.2 25.44">
                <g id="Capa_2" data-name="Capa 2">
                    <g id="Capa_1-2" data-name="Capa 1">
                        <path class="cls-1" d="M21.28,0a.91.91,0,0,0-.9.85L7.81,2.39a2.32,2.32,0,0,0-4.48.84,2.32,2.32,0,0,0,1.25,2V12.1H2.15v6.64H0v6.7H10.62v-6.7h-2V12.1H6.72V5.27a2.29,2.29,0,0,0,1.2-1.6L20.44,1.28a.85.85,0,0,0,.65.51V5.08h.46V1.78A.9.9,0,0,0,22.2.92.92.92,0,0,0,21.28,0ZM6.14,18.86H4.59V14.64H6.14ZM5.65,4.41A1.2,1.2,0,1,1,6.84,3.22,1.19,1.19,0,0,1,5.65,4.41Z"/>
                    </g>
                </g>
            </symbol>
        </svg>
        <CRow class="cardPersonalizado">
            <CCol md="12" lg="12" style="padding:0px;border-right: 1px solid #d8dbe0;">
                <div
                    class="cardHeaderPersonalizado d-flex align-items-center"
                    :style="
                        'background:' + ' #4d5a70 ' + '!important;width:100%;color:#FFFFFF;font-weight:bold;'
                    "
                >
                    <span style="margin-left:5px;"> {{ $t("label.CurrentSituationCrane") }} </span>
                </div>
                <div style="width: 100%;padding:10px;">
                    <div class="d-flex">
                        <div class="flex-fill">
                            <div class="d-flex flex-row" style="background: #edebeb;padding:5px;">
                                <div class="p-2">
                                    <h6 style="margin-bottom: 0px;">ATA</h6>
                                    <b>{{ operationInfo.ata }}</b>
                                </div>
                                <div class="p-2">
                                    <h6 style="margin-bottom: 0px;">ETD</h6>
                                    <b>{{ operationInfo.etd }}</b>
                                </div>
                            </div>
                            <div class="d-flex" style="margin-top:20px;">
                                <div class="flex-fill" style="margin-left:5px;margin-right:5px;" v-for="(item, index) in holdArray" :key="index">
                                    <div :class="index % 2 == 0 ? 'contenedorOne' : 'contenedorTwo'">
                                        <div class="d-flex" style="padding:5px;">
                                            <div class="flex-fill main-div" style="width: 10px;">
                                                <table class="main_div2">
                                                    <tr>
                                                        <td valign="top">
                                                            <h6 style="font-weight: bold;">{{ FgGeneralCargo ? $t('label.benningOperation') : $t('label.StartLoard') }}</h6>
                                                            <b style="font-weight: bold;">{{ item.DateBeginNew }}</b>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td valign="bottom">
                                                            <h6>
                                                                <b style="font-weight: bold;">{{ item.HoursInOper }}</b>
                                                                {{$t('label.HrsInOPER')}}
                                                            </h6>
                                                            <h6>
                                                                <b style="font-weight: bold;">{{ item.DelayHours }}</b>
                                                                {{$t('label.HorasRetrasos')}}
                                                            </h6>
                                                            <h6>
                                                                <b style="font-weight: bold;">{{ item.EffectiveHours }}</b>
                                                                {{$t('label.HorasEfectivas')}}
                                                            </h6>
                                                            <h6>
                                                                <b style="font-weight: bold;">{{ item.MovXHour }}</b>
                                                                MOV x HR
                                                            </h6>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <div :class="index % 2 == 0 ? 'flex-fill contenedorImagen2 main-div d-flex align-items-center' : 'flex-fill contenedorImagen main-div d-flex align-items-center'" style="text-align:center;height: 300px;width: 30px;color:#FFF">
                                                <div>
                                                    <h1 style="font-size: 2rem;">{{ FgGeneralCargo ? item.TotalQuantity ?? 0 : item.EmbarkedImplement ?? 0 }}</h1>
                                                    <h5 style="font-size: 0.9rem;">{{ `${ FgGeneralCargo ? $t('label.TotalCargo') : `${$t('label.Gamela')+'S'} ${$t('label.Loadeds')}`}` }}</h5>

                                                    <h1 style="font-size: 2rem;margin-top: 60px;">{{ item.HatchCoverMov ?? 0 }}</h1>
                                                    <h5 style="font-size: 0.9rem;">{{ $t('label.HatchMovilized') }}</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="padding:5px;margin-top:10px;">
                                            <div class="flex-fill" style="width: 10px;">
                                                <div class="d-flex align-items-center">
                                                    <div class="flex-fill" >
                                                        <svg class="bi flex-shrink-0 me-2" width="40" height="40" role="img" aria-label="watch:" :style="index % 2 == 0 ? 'color:#232F58;' : 'color:#ED5300;'"><use xlink:href="#icon-grua"/></svg>
                                                    </div>
                                                    <div class="flex-fill" >
                                                        <h1 :style="index % 2 == 0 ? 'font-size: 2rem;color:#232F58;' : 'font-size: 2rem;color:#ED5300;'">{{ item.CraneAlias }}</h1>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex-fill" style="width: 30px;text-align: right;">
                                                <h6 style="margin-bottom:2px;">{{ $t('label.hold')+'S' }}</h6>
                                                <b style="margin-bottom:2px;font-weight: bold;">{{ item.HoldText }}</b>
                                                <h6 style="margin-bottom:2px;">{{ $t('label.ultMov') }}</h6>
                                                <b style="margin-bottom:2px;font-weight: bold;">{{ item.LastMoveDateNew }}</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-fill" style="margin-left:5px;margin-right:5px;">
                                    <div class="contenedorTotal">
                                        <table class="main_div2">
                                            <tr>
                                                <td>
                                                    <h6 style="color:#FFF;">{{$t('label.MovementsPerCrane')}}</h6>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="top"  style="border-radius: 5px; background-color: rgba(255, 255, 255, 0.3); width: 80px;">
                                                    <div class="d-flex" style="padding:5px;">
                                                        <div class="flex-fill main-div" style="width: 40px; box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.8);">
                                                            <div>
                                                                <SimpleBarPercent
                                                                    width="100%"
                                                                    height="auto"
                                                                    :Series="SeriesArray" 
                                                                    :Colors="ColorArray" 
                                                                    :Categories="LabelsArray"
                                                                    LabelColor="#FFF"
                                                                ></SimpleBarPercent>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <CCol lg="12" style="color:#FFF;" class="mt-2">
                                                        <h6>TOTAL {{$t('label.movements')}}</h6>
                                                    </CCol>
                                                    <CCol>
                                                        <CRow class="d-flex flex-fill">
                                                            <div class="col-sm-5" v-for="(CraneHold, index) in CraneHold" :key="index">
                                                                <h6 style="margin-bottom:0px"><li :style="'color:'+CraneHold.Color"><label style="color:#FFF">{{ CraneHold.CraneAlias+': '+CraneHold.TotalMovements }}</label></li></h6>
                                                            </div>
                                                        </CRow>
                                                    </CCol>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="bottom" style="color:#FFF;">
                                                    <div class="d-flex" style="padding:5px;margin-top:10px;">
                                                        <div class="flex-fill" style="width: 10px;">
                                                            <div>
                                                                <h1 style="font-size: 3rem;">{{ TotalHatchCover }}</h1>
                                                                <h6>{{$t('label.hatchCover')}}</h6>
                                                            </div>
                                                        </div>
                                                        <div class="flex-fill" style="width: 30px;">
                                                            <div>
                                                                <h1 style="font-size: 3rem;">{{ TotalLoadDischarge }}</h1>
                                                                <h6>{{$t('label.LoadDischarge')}}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CCol>
        </CRow>
     </div>
</template>

<script>
    import { DateFormater } from '@/_helpers/funciones';
    import { mapState  } from 'vuex';
    import SimpleBarPercent from "../../components/SimpleBarPercent";
    //methods
    function calcPercent(value, total) {
      if(value == 0 || total == 0) return 0;

      let percent = (value/total) * 100;
      return Math.round(percent * 1e2) / 1e2;
    }
    //computed
    function operationInfo(){
      let DataVessel = this.VesselSituation;
      let DataAtaEtd = this.Vessel;
      if(Object.keys(DataVessel).length !== 0 && Object.keys(DataAtaEtd).length !== 0){
            return {
                ata: DataAtaEtd.Ata ? DateFormater.formatDateTimeWithSlash(DataAtaEtd.Ata) : 'N/A',
                etd: DataAtaEtd.Etd ? DateFormater.formatDateTimeWithSlash(DataAtaEtd.Etd) : 'N/A',
                ini: DataVessel.BeginOpsDate ? DateFormater.formatDateTimeWithSlash(DataVessel.BeginOpsDate) : 'N/A',
                end: DataVessel.EndOpsDate ? DateFormater.formatDateTimeWithSlash(DataVessel.EndOpsDate) : 'N/A',
                total: DataVessel.HoursOps ? DataVessel.HoursOps : 'N/A',
                totalProm: DataVessel.TonPromXImplement ? DataVessel.TonPromXImplement : 'N/A',
            };
        }else{
            return {
                ata:'N/A',
                etd:'N/A',
                ini:'N/A',
                end:'N/A',
                total:'N/A',
                totalProm:'N/A',
            };
        }
    }
    function bandera(){
        return this.$store.state.situacionOperativaActual.tabsOperationes === 4 && this.$store.state.situacionOperativaActual.carruselTab === 3;
    }
    function TotalLoadDischarge(){
        let total = 0;
        this.CraneHold.map(async(item) => {
            total += item.TotalMovements+item.HatchCoverMov;
        })
        return total;
    }
    function TotalHatchCover(){
        let total = 0;
        this.CraneHold.map(async(item) => {
            total += item.HatchCoverMov;
        })
        return total;
    }
    function ColorArray(){
        let data = [];
        this.CraneHold.map(async(item) => {
            data.push(item.Color);
        })
        return data;
    }
    function SeriesArray(){
        let Series = [{
            name: this.$t('label.crane'),
            data: [],
        }]
        let data = [];
        this.CraneHold.map(async(item) => {
            data.push(item.TotalMovementPercent);
        })
        Series[0].data = data;
        return Series;
    }
    function LabelsArray(){
        let data = [];
        this.CraneHold.map(async(item) => {
            data.push(item.CraneAlias);
        })
        return data;
    }
    function holdArray(){
        let data = [];
        this.CraneHold.map(async(item,index) => {
            let DateBeginNew = item.BeginDate ? DateFormater.formatDateTimeWithSlash(item.BeginDate) : 'N/A';
            let LastMoveDateNew = item.LastMoveDate ? DateFormater.formatDateTimeWithSlash(item.LastMoveDate) : 'N/A';
            let HoldText = '';
            item.HoldsJson.map(async(item2) => {
                HoldText += HoldText=='' ? item2.VesselHoldName : ' - ' + item2.VesselHoldName;
            })
            
            let CraneAliasNew = item.CraneAlias ? item.CraneAlias.replace(/[^ 0-9]/g, '') : 'N/A';

            data.push({
                ...item,
                DateBeginNew: DateBeginNew,
                HoldText: HoldText,
                LastMoveDateNew: LastMoveDateNew,
                CraneAliasNew: CraneAliasNew,
                ColorCrane: this.ColorArray[index]
            });
        });
        return data;
    }
    export default {
        name:'SituacionActualGrua',
        props:{
            Vessel: {
                type: Object,
                default: function () {
                    return {};
                },
            },
            VesselSituation:{
                type: Object,
                default: function () {
                    return {};
                },
            },
            CraneHold:{
                type:Array,
                default: () => [],
            }
        },
        components:{
            SimpleBarPercent,
        },
        methods:{
            calcPercent
        },
        computed:{
            operationInfo,
            bandera,
            holdArray,
            ColorArray,
            SeriesArray,
            LabelsArray,
            TotalLoadDischarge,
            TotalHatchCover,
            ...mapState({
                carruselTab : state=> state.situacionOperativaActual.carruselTab,
                FgGeneralCargo: state => state.situacionOperativaActual.FgGeneralCargo,
            }),
        }
    }
</script>

<style lang="scss">
    .contenedorOne{
        background:#E8F0FA;
        padding:5px;
        padding-top:10px;
        border-radius: 5px;
        height: 470px;
        h6 {
            font-size: 0.75rem;
        }
    }
    .contenedorTwo{
        background:#E0E0E0;
        padding:5px;
        padding-top:10px;
        border-radius: 5px;
        height: 470px;
        h6,b {
            font-size: 0.75rem;
        }
    }
    .contenedorTotal{
        background-image: url('/situacionOperativa/chatarra/fondo2.png');
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover;
        height: 470px;
        padding:5px;
        padding-top:10px;
        border-radius: 5px;
        h6 {
            font-size: 0.75rem;
        }
    }
    .main_div {
        left: 100px;
        height: 300px;
        width: 50px;
    }
    .sub_div {
        bottom: 0px;
        padding-bottom: 15px;
    }
    .main_div2{
        height: 100%;
        width:100%;
        border-collapse: collapse;
    }
</style>